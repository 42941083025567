.asterix{
    color: red;
    font-size: 12px;
}
.error-text{
    color:red;
    font-size: 12.5px;
    margin-top: 5px;
}


.nav-link {
    transition: all 0.3s !important;
    position: relative;
    font-family: "DM Sans", sans-serif;
    font-size: 16px !important;
    font-style: normal;
    line-height: normal;
    font-weight: 400 !important;
    color: #051312 !important;
    padding: 5px 0 !important;
    opacity: 0.5;
  }


.login-link-x {
    color: #4f8a8b; /* Green color */
    text-decoration: underline; /* Underline */
    cursor: pointer; /* Make it clickable */
    font-weight: 500; /* Optional: Makes the text a little bolder */
    font-size: 16px; /* Optional: Adjusts the font size */
  
    &:hover {
      color: #3a6a6d; /* Darker green on hover */
      text-decoration: none; /* Removes underline on hover */
    }
  }
  
  

.login{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    .loginForm{
        width: 100%;
          @media screen and (max-width: 992px){
           padding: 50px 10px;
           width: 100%;
        }
        .title{
            max-width: 331px;
            margin: 0 auto;
            h3{
                font-size: 2rem;
                margin-bottom: 10px;
                text-align: center;
                color: #051312;
            }
            p{
                font-size: 1rem;
                text-align: center;
                margin-bottom: 25px;
                color: #051312;
            }
        }
        .buttons{
            display: flex;
            // justify-content: space-between;
            justify-content: center;
            gap: 10px;
            @media screen and (max-width: 992px){
                flex-direction: column;
              
            }
            button{
                padding: 12px 10px;
                font-size: 16px;
                background-color: #000;
                color: #fff;
                border: 1px solid #000;
                border-radius: 10px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50%;
                @media screen and (max-width: 992px){
                    width: 100%;
                }
                img{
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
                &:hover{
                    background-color: #1C7A73;
                    color: #fff;
                    border: 1px solid #1C7A73;
                }
            }
        }
        .or{
            margin: 40px 0;
            p {
                width: 100%; 
                text-align: center; 
                border-bottom: 1px solid #D9D9D9; 
                line-height: 0.1em;
                margin: 10px 0 20px; 
                span { 
                    background:#fff; 
                    padding:0 10px; 
                }
             } 
             
        }
        .form-group{
            margin-bottom: 15px;
            position: relative;
            label{
                font-size: 16px;
                margin-bottom: 5px;
                font-family: "DM Serif Display", serif;
            }
            input{
                width: 100%;
                padding: 15px 20px 15px 50px;
                font-size: 14px;
                border: 1px solid #dddd;
                border-radius: 10px;
                outline: 0;
                background-color: #F5F5F5;
            }
            img{
                position: absolute;
                left: 15px;
                top: 45px;
            
            }
        }
        .forget{
            text-align: right;
            margin-bottom: 40px;
            margin-top: -10px;
            a{
                color: #1C7A73;
                font-size: 14px;
                font-weight: 500;
                text-decoration: underline !important;
            }
        }
        .loginBtn{
            padding: 10px 20px;
            font-size: 1rem;
            background-color: #1C7A73;
            color: #fff;
            border: 2px solid #1C7A73;
            border-radius: 10px;
            cursor: pointer;
            width: 100%;
            font-weight: 600;
            &:hover{
                background-color: #fff;
                color: #1C7A73;
                border: 2px solid #1C7A73;
            }
        }
        .register{
           p{
            text-align: center;
            margin-top: 30px;
            margin-bottom: 0;
            a{
                color: #1C7A73;
                font-size: 14px;
                font-weight: 500;
                text-decoration: underline !important;
            }
           }
        }
    }
}










.password-input-wrapper {
    position: relative;
    width: 100%;
  }
  
  input[type="password"], input[type="text"] {
    width: 100%;
    padding-right: 30px;  // Add padding on the right side for the icon
  }
  
  .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;  // Adjust size as needed
    color: #333;      // Customize the color of the eye icon
  }
  
.Blogbanner{
    background-color: #000;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(49, 61, 77, 0.41);
        z-index: -1;
    }
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../../public/images/ourBlogs.svg) no-repeat center;
        z-index: 0;
    }
    .banner-heading{
        font-size: 72px;
        margin-bottom: 10px;
        @media screen and (max-width:992px){
            font-size: 48px;
            
        }
    }
    P{
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 0;
        @media screen and (max-width:992px){
            font-size: 16px;
        }
    }
    .formGroup{
        position: relative;
        max-width: 616px;
        margin: 40px auto 0;
        input{
            width: 100%;
            height: 60px;
            border: 0;
            border-radius: 10px;
            padding:0 20px 0 50px;
            font-size: 18px;
            color: #051312;
            background-color: #fff;
            outline: 0;
            @media screen and (max-width:992px){
                height: 50px;
                font-size: 16px;
            }
        }
        img{
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);

        }
    }
}
.MainBlogs{
    background: url(../../../public/images/StepBack.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0 70px;
    overflow: hidden;
    @media screen and (max-width:1200px){
        padding: 70px 0 70px;
    }
    @media screen and (max-width:992px){
        padding: 50px 0 50px;
        
    }
    .mainImg{
       img{
        width: 100%;
        height: 355px;
        border-radius: 10px;
       }
    }
    .content{
        max-width: 436px;
        @media screen and (max-width:992px){
           margin-top: 20px;
        }
        .time{
            display: flex;
        align-items: center;
        margin-bottom: 20px;
        p{
            color: #1C7A73;
            font-size: 16px;
            margin: 0;
            position: relative;
            display: flex;
            align-items: center;
            font-weight: 600;
            &:last-child{
                &::before{
                    content: '';
                    width: 6px;
                height: 6px;
                border-radius: 100px;
                background: #1C7A73;
                display: block;
                margin: 0 10px;
                }
            }
        }
       
        }
        h2{
            font-size: 36px;
            color: #051312;
            line-height: 46px;
            margin: 20px 0 16px;
            @media screen and (max-width:1200px){
                font-size: 32px;
            }
        }
        .date{
            display: flex;
        align-items: center;
        margin-bottom: 20px;
        p{
            img{
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border-radius: 100px;
                object-fit: cover;
            }
            color: #05131297;
            font-size: 18px;
            margin: 0;
            position: relative;
            display: flex;
            align-items: center;
            font-weight: 600;
            &:last-child{
                &::before{
                    content: '';
                    width: 6px;
                height: 6px;
                border-radius: 100px;
                background: #C7C7CC;
                display: block;
                margin: 0 10px;
                }
            }
        }
       
        }
    }
    .blogs{
        margin-top: 40px;
        @media screen and (max-width:992px){
            margin: 0px;
         }
        .BlogList{
            margin-top: 20px;
            a{
                display: block;
                .mainImg{
                    width: 100%;
                    height: 200px;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    object-fit: cover;
                }
            }
            .time{
                display: flex;
            align-items: center;
            margin-bottom: 10px;
            p{
                color: #1C7A73;
                font-size: 14px;
                margin: 0;
                position: relative;
                display: flex;
                align-items: center;
                font-weight: 600;
                &:last-child{
                    &::before{
                        content: '';
                        width: 6px;
                    height: 6px;
                    border-radius: 100px;
                    background: #1C7A73;
                    display: block;
                    margin: 0 10px;
                    }
                }
            }
           
            }
            h2{
                font-size: 24px;
                color: #051312;
                line-height: 34px;
                margin: 0 0 16px;
                @media screen and (max-width:1200px){
                    font-size: 20px;
                }
            }
            p{
                font-size: 16px;
                color: #051312;
                margin-bottom: 20px;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
            
                @media screen and (max-width:1200px){
                    font-size: 14px;
                }
            }
            .date{
                display: flex;
            align-items: center;
            margin-bottom: 20px;
            p{
                img{
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                    border-radius: 100px;
                    object-fit: cover;
                }
                color: #05131297;
                font-size: 14px;
                margin: 0;
                position: relative;
                display: flex;
                align-items: center;
                font-weight: 600;
                &:last-child{
                    &::before{
                        content: '';
                        width: 6px;
                    height: 6px;
                    border-radius: 100px;
                    background: #C7C7CC;
                    display: block;
                    margin: 0 10px;
                    }
                }
            }
           
            }
        }
       }
}
.AllBlog{
   background-color: #F4F9F9;
    padding: 70px 0 70px;
    overflow: hidden;
    @media screen and (max-width:1200px){
        padding: 70px 0 70px;
    }
    @media screen and (max-width:992px){
        padding: 50px 0 50px;
        
    }
    .title{
        margin: 0 auto 20px;
        h2{
            font-size: 24px;
            color: #000;
            margin-bottom: 0;
            @media screen and (max-width:1200px){
                font-size: 20px;
              }
        }
    }
   .blogs{
    .BlogList{
        margin-top: 20px;
        a{
            display: block;
            .mainImg{
                width: 100%;
                height: 200px;
                border-radius: 10px;
                margin-bottom: 20px;
                object-fit: cover;
            }
        }
        .time{
            display: flex;
        align-items: center;
        margin-bottom: 10px;
        p{
            color: #1C7A73;
            font-size: 14px;
            margin: 0;
            position: relative;
            display: flex;
            align-items: center;
            font-weight: 600;
            &:last-child{
                &::before{
                    content: '';
                    width: 6px;
                height: 6px;
                border-radius: 100px;
                background: #1C7A73;
                display: block;
                margin: 0 10px;
                }
            }
        }
       
        }
        h2{
            font-size: 24px;
            color: #051312;
            line-height: 34px;
            margin: 0 0 16px;
            @media screen and (max-width:1200px){
                font-size: 20px;
            }
        }
        p{
            font-size: 16px;
            color: #051312;
            margin-bottom: 20px;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        
            @media screen and (max-width:1200px){
                font-size: 14px;
            }
        }
        .date{
            display: flex;
        align-items: center;
        margin-bottom: 20px;
        p{
            img{
                width: 32px;
                height: 32px;
                margin-right: 10px;
                border-radius: 100px;
                object-fit: cover;
            }
            color: #05131297;
            font-size: 14px;
            margin: 0;
            position: relative;
            display: flex;
            align-items: center;
            font-weight: 600;
            &:last-child{
                &::before{
                    content: '';
                    width: 6px;
                height: 6px;
                border-radius: 100px;
                background: #C7C7CC;
                display: block;
                margin: 0 10px;
                }
            }
        }
       
        }
    }
    .more{
        font-size: 16px;
        font-weight: 500;
        background: linear-gradient(285deg, #0B5B4C 0, #2B9F97 95%);
        color: white;
        width: 137px;
        height: 53px;
        outline: 0;
        border: 0;
        margin: 60px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        transition: all 0.4s;
        @media screen and (max-width:992px){
            margin: 30px auto 0;
           }
        &:hover{
            background: linear-gradient(90deg, #0B5B4C 0, #2B9F97 95%);
            transition: all 0.4s;
        }
    }
   }

}

.membershipCards{
  background-color: #000;
    padding: 70px 0;
    @media screen and (max-width:992px){
        padding: 50px 0;
       }
    .title{
        text-align: center;
        margin:0 auto 60px;
        span{
            font-size: 18px;
            font-style: italic;
            color: #FD8311;
            display: block;
        }
        h2{
            font-size: 48px;
            color: #fff;
            margin-bottom: 15px;
            @media screen and (max-width:1200px){
                font-size: 38px;
                line-height: 55px;
              }
        }
        p{
            font-size: 20px;
            color: #BDBFD4;
            margin: 0 0 25px;
            margin: 0 auto 35px;
        }
    }
   .cards{
    .card{
        position: relative;
        background: #000;
        border-radius: 15px;
        padding: 40px 30px;
        border: 1px solid #4F4F4F;
        cursor: pointer;
        height: 100%;
        &:hover{
            border: 1px solid #1C7A73;
            button{
                background-color: #1F837B;
            }
        }
        .popular{
            background: #6A4DFF;
            color: #F4F9F9;
            position: absolute;
            right: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 3px 15px;
            font-size: 14px;
        }
        .price{
            font-size: 33px;
            color: #FD8311;
            margin-bottom: 20px;
            line-height: 32px;
            @media screen and (max-width:1200px){
                font-size: 28px;
              }
        }
        .card-title{
            font-size: 72px;
            color: #fff;
            line-height: 68px;
            margin-bottom: 20px;
            font-weight: 600;
            @media screen and (max-width:1200px){
                font-size: 50px;
              }
        }
        .card-text{
            font-size: 20px;
            color: #fff;
            margin-bottom: 20px;
            @media screen and (max-width:1200px){
                font-size: 18px;
              }
        }
        button{
            width: 100%;
            height: 70px;
            background-color: #4F4F4F;
            color: #fff;
            font-size: 22px;
            border-radius: 15px;
            border: 0;
            outline: 0;
        }
        ul{
            list-style: none;
            margin: 0;
            li{
                color: #BDBFD4;
                margin: 20px 0;
                .numbers{
                    color: white;
                }
            }
        }
    }
   }
}
.Aboutbanner{
    background-color: #000;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(49, 61, 77, 0.41);
        z-index: -1;
    }
    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../../public/images/About.svg) no-repeat center;
        z-index: 0;
    }
    .banner-heading{
        font-size: 72px;
        margin-bottom: 10px;
        @media screen and (max-width:992px){
            font-size: 48px;
            
        }
    }
    P{
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 0;
        @media screen and (max-width:992px){
            font-size: 16px;
        }
    }
}
.whoWeAre{
    background: url(../../../public/images/StepBack.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0 140px;
    overflow: hidden;
    @media screen and (max-width:1200px){
        padding: 70px 0 70px;
    }
    @media screen and (max-width:992px){
        padding: 50px 0 50px;
        
    }
    .imgBlock{
        position: relative;
        img{
            width: 100%;
            height: auto;
        }
        .card{
            border-radius: 12px;
            padding: 20px;
            text-align: center;
            background-color: white;
            border: 0;
            position: absolute;
            bottom: -60px;
            right: -40px;
            @media screen and (max-width:992px){
                bottom: -30px;
                right: -5px;
                
            }
            h2{
                color: #2B9F97;
                font-size: 72px;
                line-height: 55px;
                margin-bottom: 5px;
            }
            p{
                font-size: 24px;
                color: #484F56;
                margin: 0;
            }
        }
    }
    .content{
        max-width: 532px;
        margin: 40px auto 0;
        z-index: 99;
    position: relative;
    @media screen and (max-width:1200px){
       width: 400px;
    }
    @media screen and (max-width:992px){
        width: 100%;
    }
        span{
            font-size: 18px;
            font-style: italic;
            color: #2B9F97;
            display: block;
        }
        h2{
            margin: 20px 0;
            font-size: 48px;
            color: #051312;
            line-height: 52px;
            @media screen and (max-width:1200px){
                font-size: 36px;
                line-height: 40px;
                
            }
        }
        p{
            font-size: 18px;
            color: #051312;
            margin-bottom: 30px;
            @media screen and (max-width:1200px){
                font-size: 16px;
                
            }
        }
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            li{
                display: flex;
                align-items: flex-start;
                gap: 20px;
                h4{
                    font-size: 24px;
                    color: #313D4D;
                }
                p{
                    color: #051312;
                    font-size: 18px;
                    @media screen and (max-width:1200px){
                        font-size: 16px;
                        
                    }
                }
            }
        }
    }
}
.changeLook{
    background: url(../../../public/images/look.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 90px 0;
    height: 636px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width:1200px){
        height: auto;
    }
    .content{
       
        max-width: 692px;
        margin: 0 auto;
        text-align: center;
        .banner-heading{
            margin-top: 80px;
            font-size: 48px;
            color: white;
            margin-bottom: 25px;
            @media screen and (max-width:1200px){
                font-size: 36px;
            }
        }
        p{
            font-size: 18px;
            color: white;
            margin-bottom: 60px;
            @media screen and (max-width:1200px){
                font-size: 16px;
            }
        }
        .more{
            font-size: 16px;
            font-weight: 500;
            background: linear-gradient(285deg, #0B5B4C 0, #2B9F97 95%);
            color: white;
            width: 137px;
            height: 53px;
            outline: 0;
            border: 0;
            margin: 60px auto 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            transition: all 0.4s;
            @media screen and (max-width:992px){
                margin: 30px auto 0;
               }
            &:hover{
                background: linear-gradient(90deg, #0B5B4C 0, #2B9F97 95%);
                transition: all 0.4s;
            }
        }
    }
}
.ourValues{
    background-color: #f4f9f9;
    padding: 70px 0 90px;
    overflow: hidden;
    .content{
        span{
            font-size: 18px;
            font-style: italic;
            color: #2B9F97;
            display: block;
        }
        h2{
            margin: 20px 0;
            font-size: 48px;
            color: #051312;
            line-height: 52px;
            @media screen and (max-width:1200px){
                font-size: 36px;
                line-height: 40px;
                
            }
        }
        p{
            font-size: 18px;
            color: #051312;
            margin-bottom: 30px;
            @media screen and (max-width:1200px){
                font-size: 16px;
                
            }
        }
    }
    .cardBlock{
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-top: 40px;
        @media screen and (max-width:767px){
            flex-direction: column;
            gap: 0;
        }
        .block{
            position: relative;            
            width: 50%;
            @media screen and (max-width:767px){
                width: 100%;
            }
            .line{
                position: absolute;
                top: -20px;
                left: -20px;
            }
            .line2{
                position: absolute;
                bottom: -20px;
                right: -20px;
            }
            .card{
                border-radius: 20px;
                padding: 30px;
                text-align: center;
                background-color: white;
                border: 0;
                @media screen and (max-width:992px){
                    width: 100%;
                    margin-bottom: 20px;
                }
                .value{
                    width: 50px;
                    height: 50px;
                    margin: 0 auto;
                }
                h3{
                    color: #313D4D;
                    font-size: 24px;
                    margin: 15px 0 10px;
                    line-height: normal;
                }
                p{
                    font-size: 18px;
                    color: #484F56;
                    margin: 0;
                }
            }
        }
    }
}
.convertHome{
    background: url(../../../public/images/convertHome.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0;
    position: relative;

    .content{
        padding: 40px;
        background-color: #f4f9f917;
        border-radius: 40px;
        h3{
            font-size: 48px;
            color: #051312;
            margin-bottom: 20px;
            color: #fff;
            @media screen and (max-width:1200px){
                font-size: 36px;
            }
        }
        p{
            font-size: 18px;
            color: #051312;
            margin-bottom: 20px;
            color: #ffffffcb;
            @media screen and (max-width:1200px){
                font-size: 16px;
            }
        }
        .more{
            font-size: 16px;
            font-weight: 500;
            background: linear-gradient(285deg, #0B5B4C 0, #2B9F97 95%);
            color: white;
            width: 137px;
            height: 53px;
            outline: 0;
            border: 0;
            margin: 60px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            transition: all 0.4s;
            @media screen and (max-width:992px){
                margin: 30px auto 0;
               }
            &:hover{
                background: linear-gradient(90deg, #0B5B4C 0, #2B9F97 95%);
                transition: all 0.4s;
            }
        }
    }
        .convert{
        width: auto;
        position: absolute;
        right: 0;
        top: 52%;
        transform: translate(0, -50%);
        @media (max-width: 1300px){
            width: 500px;
        }
        @media (max-width: 992px){
            width: 100%;
            position: relative;
            margin-top: 30px;
            top: unset;
            transform: none;
        }
    }
}
.testimonials{
   background:url(../../../public//images/testimonials.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0;
    @media screen and (max-width:992px){
        padding: 50px 0;
       }
    .title{
        text-align: center;
        margin:0 auto 60px;
        max-width: 740px;
        span{
            font-size: 18px;
            font-style: italic;
            color: #FD8311;
            display: block;
        }
        h2{
            font-size: 48px;
            color: #000;
            margin-bottom: 15px;
            @media screen and (max-width:1200px){
                font-size: 38px;
                line-height: 55px;
              }
        }
        p{
            font-size: 20px;
            color: #000000a2;
            margin: 0 0 25px;
            margin: 0 auto 35px;
        }
    }
    .mySwipers {
        border-radius: 0;
        padding: 0 30px;
        .swiper {
            width: 100%;
            height: 100%;
        }
        .swiper-slide{
            border-radius: 0;
        }
        .swiper-button-prev {
            &::after {
                display: none;
            }
        }
        .swiper-button-next {
            &::after {
               display: none;
            }
        }
        .card{
            background: #f4f9f9;
            border: none;
            border-radius: 45px;
            padding: 50px 40px;
            .head{
                display: flex;
                align-items: center;
                gap: 10px;
                justify-content: space-between;
                margin-bottom: 20px;
                .quote{
                    width: 30px;
                    height: 30px;
                }
                .user{
                    display: flex;
                align-items: center;
                gap: 10px;
                    .userImg{
                        width: 50px;
                        height: 50px;
                        border-radius: 100px;
                    }
                    .block{
                        h5{
                            font-size: 18px;
                            color: #1C2022;
                            margin: 0;
                        }
                        .rating{
                            display: flex;
                            align-items: center;
                            gap: 1px;
                            margin: 3px 0 1px;
                            img{
                                width: 15px;
                                height: 15px;
                            }
                        }
                        span{
                            font-size: 16px;
                            color: #484F56;
                            font-style: italic;
                        }
                    }
                }
            }
            h2{
                font-size: 24px;
                color: #1C2022;
                margin: 0px;
            }
        }
    }
}
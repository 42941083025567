.room-designer {
  overflow: hidden;
  .back-button {
    font-size: 16px;
    font-weight: 500;
    background: linear-gradient(285deg, #0B5B4C 0, #2B9F97 95%);
    color: white;
    margin-right: 20px;
    width: 100px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    transition: all 0.4s;
  }

  .next-button {
    font-size: 16px;
    font-weight: 500;
    background: linear-gradient(285deg, #0B5B4C 0, #2B9F97 95%);
    color: white;
    margin-left: 20px;
    width: 100px;
    height: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    transition: all 0.4s;
  }

  .dimension-display {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .top-bar {
    display: flex;
    flex-direction: column; // Change to column for small screens
    justify-content: space-between;
    padding-left: 40px; // Adjust padding for smaller screens
    padding-right: 40px;
    background-color: #f5f5f5;
    align-items: center;
    margin: 20px;
    border-radius: 50px;
    

    @media (min-width: 768px) {
      flex-direction: row; // Revert to row for larger screens
    }
  }

  .input-group {
    display: flex;
    gap: 10px;
    flex-wrap: wrap; // Allow input fields to wrap

    input {
      width: 100%; // Make input fields full width on small screens
      max-width: 100px; // Limit max width
    }
  }

  .room-layout {
    display: flex;
    flex-direction: column; // Change to column for smaller screens
    justify-content: flex-start;

    @media (min-width: 768px) {
      flex-direction: row; // Revert to row for larger screens
    }
  }

  .furniture-list {
    margin: 10px;
    width: 90%; // Full width on small screens
    padding: 10px;
    background-color: #eee;
    

    @media (min-width: 768px) {
      width: 200px; // Specific width on larger screens
    }

    .furniture-item {
      padding: 5px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      background-color: #fff;
      cursor: pointer;

      &.active {
        background-color: #d3d3d3;
      }
    }
    .rotate-icon{
      font-size: 40px;
      @media (min-width: 768px) {
        font-size: 20px;
      }
    }
  }

  .room-area {
    position: relative;
    background-color: #eee;
    // border: 12px solid #b2bebf;
    // margin-top: 40px;  
    margin-bottom: 40px;
   
    width: 90%; // Full width on small screens
    
    
  
    @media (min-width: 768px) {
      margin-left: 30%;
    }
  }
}





// Additional styles for mobile responsiveness
@media (max-width: 768px) {
  .top-bar {
    padding: 10px; // Reduce padding on small screens
  }
}

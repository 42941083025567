.footer{
    padding: 70px 0;
    overflow: hidden;
    @media screen and (max-width:992px){
        padding: 50px 0;
       }
    .logo{
        width: 180px;
        margin-bottom: 15px;
    }
    p{
        font-size: 16px;
        color: #051312;
        margin-bottom: 30px;
        max-width: 413px;
    }
    .social{
        display: flex;
        align-items: center;
        gap: 10px;
        @media screen and (max-width:768px){
            margin-bottom: 40px;
           }
    }
    .links{
        h4{
            font-family: 'DM Sans', sans-serif;
            font-size: 16px;
            font-weight: bold;
            color: #1C7A73;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
        ul{
            padding: 0;
            margin: 0;
            list-style: none;
            li{
                margin-bottom: 15px;
                button,a{
                color: #000;
                font-size: 16px;
                text-transform: capitalize;
                background-color: transparent;
                border: 0;
                outline: 0;
                padding: 0;
                &:hover{
                    color: #1C7A73;
                }
                }
                &:last-child{
                    margin: 0;
                }
            }
        }

    }
}
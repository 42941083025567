.swiper {
    width: 100%;
    height: 100%;
    border-top-left-radius: 195px;
    border-bottom-left-radius: 195px;
  }
  .swiperPosition{
    position: relative;
   
  }
  
  .swiper-slide {
    border-top-left-radius: 195px;
    border-bottom-left-radius: 195px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .mySwiper {
    .swiper-button-next,
    .swiper-button-prev {
        width: 48px;
        height: 48px;
        transition: all 0.4s ease-in-out;
        position: absolute;
        &.swiper-button-next{
          top: 39%;
          left: 90%;
          transform: translate(-8%, -50%);
        @media screen and (max-width: 1100px){
          top: 34%;
        }
        @media screen and (max-width:525px){
          left: 85%;
        }
        }
        &.swiper-button-prev{
          top: 66%;
          left: 90%;
          transform: translate(-8%, -50%) scaleY(-1);
          @media screen and (max-width: 1100px){
            top: 72%;
          }
          @media screen and (max-width:525px){
            left: 85%;
          }
        }
      &:after {
        content: '';
        width: 34px;
        height: 34px;
        background: url("../../../../../public/images/upArrow.svg");
        background-size: 100% 100%;
      }
    }
  }
  .swiper-pagination {
    display: flex;
    justify-content: center;
    background: #5f6264d1;
    width: 95px !important;
    border-radius: 100px;
    padding: 15px 0;
    position: absolute;
    left: 90% !important;
    bottom: 50% !important;
    transform: translate(-30%, 20%) rotate(90deg);
    @media screen and (max-width:525px){
      display: none;
    }
  }

.swiper-pagination-bullet {
    width: 6px;
    height: 2px;
    background-color: #b9b9b9;
    opacity: 0.6;
    margin: 0 5px;
    transition: transform 0.3s ease-in-out;
    border-radius: 6px;
}

.swiper-pagination-bullet-active {
    transform: scale(1.5);
    opacity: 1;
    background-color: #fff;
    width: 12px;
}

/* Hover effect for non-active bullets */
.swiper-pagination-bullet:hover {
    transform: scale(1.2);
}

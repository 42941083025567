.editProfile{
    background: url(../../../public/images/blogback.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0;
    @media screen and (max-width:992px){
        padding: 50px 0;
       }
    .card{
        background: url(../../../public/images/cardBack.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 100px 30px 30px;
        border-radius: 30px;
        border: 0;
        @media screen and (max-width:600px){
            padding: 100px 15px 30px;
           }
        .cardInner{
            background: #F4F9F9;
            border: none;
            border-radius: 30px;
            padding: 30px;
            padding-top: 50px;
            @media screen and (max-width:600px){
                padding: 30px 15px;
               }
            .user{
                text-align: center;
                position: relative;
                width: 160px;
                margin: 0 auto 60px;
                .camera{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    
                }
            }
            .form-group{
                margin-bottom: 15px;
                position: relative;
                label{
                    font-size: 16px;
                    margin-bottom: 10px;
                    font-weight: 600;
                }
                input{
                    width: 100%;
                    padding: 15px 20px 15px 20px;
                    font-size: 14px;
                    border: 1px solid #fff;
                    border-radius: 10px;
                    outline: 0;
                    background-color: #fff;
                }
            }
           
            .seeAll{
                font-size: 16px;
                font-weight: 500;
                background: linear-gradient(285deg, #0B5B4C 0, #2B9F97 95%);
                color: white;
                width: 137px;
                height: 53px;
                margin-top: 20px;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                transition: all 0.4s;
                @media screen and (max-width:992px){
                    margin: 30px auto 0;
                   }
                img{
                    margin-left: 10px;
                    width: 20px;
                    transition: all 0.4s;
                }
                &:hover{
                    background: linear-gradient(90deg, #0B5B4C 0, #2B9F97 95%);
                    transition: all 0.4s;
                    img{
                        margin-left: 20px;
                        transform: translate(0, 0);
                    }
                }
            }
        }
    }
}
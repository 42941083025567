.StepBack {
  background-image: url("../../../public/images/StepBack.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 90px 0;
  @media screen and (max-width:600px){
    padding: 50px 0;
   }
  .title {
    text-align: center;
    margin-bottom: 60px;

    h2 {
      font-size: 48px;
      color: #000;
      margin-bottom: 15px;

      @media screen and (max-width:1200px) {
        font-size: 38px;
        line-height: 55px;
      }
    }

    p {
      font-size: 20px;
      color: #000000a2;
      margin: 0 0 25px;
      margin: 0 auto 35px;
      @media screen and (max-width:600px){
        font-size: 16px;
       }
    }
  }

  .card {
    width: 100%;
    padding: 64px;
    background-color: #fff;
    box-shadow: 0px -1px 0px 1px rgba(0, 0, 0, 0.1);
    border-radius: 48px;
    border: 0;
    @media screen and (max-width:600px){
      padding: 40px 10px;
     }

    .steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 316px;
      margin: 0 auto;
      @media screen and (max-width:600px){
       width: 100%;
       }
    }

    .step-indicators {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .step-indicator {
      width: 64px;
      height: 40px;
      border-radius: 100px;
      background: #e6e7e7;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 9;
      font-size: 24px;
      font-family: "DM Serif Display", serif;
    }

    .step-indicator.active {
      background-color: rgba(28, 122, 115, 1);
      /* Active step color */
    }

    .step-indicator.next {
      background-color: black;
      color: white;
    }

    .step-connections {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      top: -20px;
    }

    .connection {
      flex: 1;
      height: 2px;
      background-color: #e6e7e7;
      /* Default connection line color */
      margin: 0 10px;
    }

    .connection.active {
      background: #051312;
    }
.chooseStyle{
  width: 100%;
}
    .options {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 16px;
      flex-direction: column;
      width: 270px;
      margin: 0 auto;
      @media screen and (max-width:768px){
        width: 100%;
        }
      .select{
        margin: 32px auto 49px;
        font-size: 20px;
        font-weight: 400;
      }

      .option-button {
        background: #fff;
        border: 1px solid #0513121a;
        border-radius: 100px;
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.3s, border-color 0.3s;
        display: flex;
        width: 100%;
        height: 61px;
        align-items: center;
        font-weight: 500;
        span{
          width: 100%;
          text-align: center;
          margin-right: 20px;
        }
      }
  
      .option-button.selected {
        border-color: #1c7a73;
        background: radial-gradient(100% 911.58% at 0% 0%, #2b9f9708 0%, #1c7a7308 100%);
      }
    }


    .buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 64px;
      align-items: center;
      @media screen and (max-width:600px){
        flex-direction: column;
        gap: 20px;
        margin-top: 40px;
        }
    }

    .help{
      display: flex;
      align-items: center;
      filter: opacity(0.5);
      img{
        width: 20px;
        height: 20px;
        margin-right: 10px;
        filter: opacity(0.5);
      }
      span{
        font-size: 16px;
      color: rgba(5, 19, 18, 1);
      }
    }

    .back-button,
    .next-button,
    .submit-button {
      border: 1px solid #000;
      cursor: pointer;
      font-size: 16px;
      color: #fff;
      width:163px;
      height: 53px;
      gap: 8px;
      border-radius:100px;
      background: #000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .back-button{
      width: 125px;
      height: 53px;
    }
    .back-button.first-step {
      background: #00000040;
      border: 1px solid transparent;
    }

    .back-button:not(.first-step) {
      background-color: #000;
    }
    
  }
}
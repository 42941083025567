




.inspirationBanner{
    background-color: #000;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(49, 61, 77, 0.41);
        z-index: -1;
    }
    
    .banner-heading{
        font-size: 72px;
        margin-bottom: 10px;
        @media screen and (max-width:992px){
            font-size: 48px;
            
        }
    }
    P{
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 0;
        @media screen and (max-width:992px){
            font-size: 16px;
        }
    }
    .formGroup{
        position: relative;
        max-width: 616px;
        margin: 40px auto 0;
        input{
            width: 100%;
            height: 60px;
            border: 0;
            border-radius: 10px;
            padding:0 20px 0 50px;
            font-size: 18px;
            color: #051312;
            background-color: #fff;
            outline: 0;
            @media screen and (max-width:992px){
                height: 50px;
                font-size: 16px;
            }
        }
        img{
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);

        }
    }
}
.boards{
    background: url(../../../public/images/blogback.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0;
 
    @media screen and (max-width:992px){
        padding: 50px 0;
       }
    .title{
        text-align: center;
        margin-bottom: 40px;
        h2{
            font-size: 48px;
            color: #000;
            margin-bottom: 15px;
            @media screen and (max-width:1200px){
                font-size: 38px;
                line-height: 55px;
              }
        }
        p{
            font-size: 20px;
            color: #000000a2;
            margin: 0 0 25px;
            margin: 0 auto 35px;
        }
    }
    .mySwipers {
        border-radius: 0;
        padding: 0 30px;
    }
    .card{
        background: #fff;
        border: none;
        border-radius: 45px;
        padding: 10px;
        margin-top: 20px;
        cursor: pointer;
        &:hover {
           h3{
            color: #0c0c0c; /* Optional: Change the color on hover */
            white-space: normal; /* Allow the text to wrap */
            overflow: visible;   /* Show full text */
            text-overflow: clip; /* Disable ellipsis on hover */
           }
        }
        h3 {
            font-size: 16px;
            color: #051312;
            margin-bottom: 10px;
            white-space: nowrap; /* Keep text in one line */
            overflow: hidden; /* Hide any overflow */
            text-overflow: ellipsis; /* Add the ellipsis (...) */
            width: 100%; /* Ensure it takes full width of container */
            display: inline-block; /* Make sure ellipsis works by ensuring block display */
            cursor:pointer; /* Change cursor to indicate hoverability */
            transition: color 0.3s; /* Smooth transition for color change */
           
          
        }
        .head{
            position: relative;
            margin-bottom: 15px;
            .blogImg{
                width: 70%;
                height: 100px;
                border-radius: 45px;
                object-fit: cover;
                &:hover{
            transform: scale(1.03);
            transition: 500ms;
                }
            }
            .date{
                position: absolute;
                top: 90%;
                left: 20px;
                transform: translate(0, -90%);
                background-color: #0000009e;
                padding: 10px;
                border-radius: 100px;
                p{
                    color: #fff;
                    font-size: 11px;
                    display: flex;
                    align-items: center;
                    margin: 0;
                    img{
                        width: 15px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .content{
            padding: 0 10px 10px;
           

           
            p{
                font-size: 12px;
                color: #05131272;
                margin-bottom: 20px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                
            }
            .readMore{
                font-size: 14px;
                font-weight: 500;
                background: #000;
                color: white;
                width: 100%;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                transition: all 0.4s;
                img{
                    margin-left: 10px;
                    width: 10px;
                    height: 10px;
                    transition: all 0.4s;
                }
                &:hover{
                    transition: all 0.4s;
                    
                    img{
                        margin-left: 20px;
                        transform: translate(0, 0);
                    }
                }
            }            
        }
    }
    .seeAll{
        font-size: 16px;
        font-weight: 500;
        background: linear-gradient(285deg, #0B5B4C 0, #2B9F97 95%);
        color: white;
        width: 137px;
        height: 53px;
        margin: 60px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        transition: all 0.4s;
        @media screen and (max-width:992px){
            margin: 30px auto 0;
           }
        img{
            margin-left: 10px;
            width: 20px;
            transition: all 0.4s;
        }
        &:hover{
            background: linear-gradient(90deg, #0B5B4C 0, #2B9F97 95%);
            transition: all 0.4s;
            img{
                margin-left: 20px;
                transform: translate(0, 0);
            }
        }
    }
}

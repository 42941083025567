
.seeBoard {
    display: flex;
    justify-content: flex-start;
    background: url(../../../public/images/blogback.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0;

    @media screen and (max-width: 992px) {
        padding: 50px 0;
    }

    .title {
        text-align: center;
        margin-bottom: 60px;

        h2 {
            font-size: 48px;
            color: #000;
            margin-bottom: 15px;

            @media screen and (max-width: 1200px) {
                font-size: 38px;
                line-height: 55px;
            }
        }

        p {
            font-size: 20px;
            color: #000000a2;
        }

        span {
            font-size: 14px;
            color: #2B9F97;
            font-weight: 900;
            margin: 0 auto 35px;
        }
    }

    .tab-buttons {
        margin-bottom: 30px;
        display: flex;
        gap: 30px;

        @media screen and (max-width: 768px) {
            overflow-x: scroll;
            width: 100%;
            gap: 20px;
        }

        button {
            color: #7f8685; 
            background-color: transparent;
            border: 0;
            border-bottom: 2px solid transparent; 
            padding: 0; 
            margin-right: 10px; 
            margin-bottom: 10px;
            cursor: pointer; 
            transition: background-color 0.3s, color 0.3s; 
        }
    }

    .mySwipers {
        border-radius: 0;
        padding: 0 30px;
    }

    .card {
        background: #fff;
        border: none;
        border-radius: 45px;
        padding: 10px;
        
      

        .head {
            position: relative;
            margin-bottom: 15px;

            .blogImg {
                width: 100%;
                height: 200px;
                border-radius: 45px;
                object-fit: cover;
            }

            .date {
                position: absolute;
                top: 90%;
                left: 20px;
                transform: translate(0, -90%);
                background-color: #0000009e;
                padding: 10px;
                border-radius: 100px;

                p {
                    color: #fff;
                    font-size: 11px;
                    display: flex;
                    align-items: center;
                    margin: 0;

                    img {
                        width: 15px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .content {
            padding: 0 10px 10px;

            h3 {
                font-size: 16px;
                color: #051312;
                margin-bottom: 10px;
                white-space: nowrap; /* Keep text in one line */
                overflow: hidden; /* Hide any overflow */
                text-overflow: ellipsis; /* Add the ellipsis (...) */
                width: 100%; /* Ensure it takes full width of container */
                display: inline-block; /* Make sure ellipsis works by ensuring block display */
                cursor: pointer; /* Change cursor to indicate hoverability */
                transition: color 0.3s; /* Smooth transition for color change */

                &:hover {
                    color: #0c0c0c; /* Optional: Change the color on hover */
                    white-space: normal; /* Allow the text to wrap */
                    overflow: visible;   /* Show full text */
                    text-overflow: clip; /* Disable ellipsis on hover */
                }
            }

            p {
                font-size: 12px;
                color: #05131272;
                margin-bottom: 20px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }

            .readMore {
                font-size: 14px;
                font-weight: 500;
                background: #000;
                color: white;
                width: 100%;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                transition: all 0.4s;

                img {
                    margin-left: 10px;
                    width: 10px;
                    height: 10px;
                    transition: all 0.4s;
                }

                &:hover {
                    transition: all 0.4s;

                    img {
                        margin-left: 20px;
                        transform: translate(0, 0);
                    }
                }
            }            
        }
    }

    .seeAll {
        font-size: 16px;
        font-weight: 500;
        background: linear-gradient(285deg, #0B5B4C 0, #2B9F97 95%);
        color: white;
        width: 137px;
        height: 53px;
        margin: 60px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        transition: all 0.4s;

        @media screen and (max-width: 992px) {
            margin: 30px auto 0;
        }

        img {
            margin-left: 10px;
            width: 20px;
            transition: all 0.4s;
        }

        &:hover {
            background: linear-gradient(90deg, #0B5B4C 0, #2B9F97 95%);
            transition: all 0.4s;

            img {
                margin-left: 20px;
                transform: translate(0, 0);
            }
        }
    }

    // Flexbox settings for card layout
    .row {
        display: flex;
        flex-wrap: wrap; /* Allow wrapping of items */
        justify-content: flex-start; /* Optional: distribute space evenly */
        margin: 0 -15px; /* Adjust for your spacing */
    }

    .col-lg-3, .col-md-4 {
        flex: 0 0 calc(33.33% - 30px); /* Each column takes up about 33.33% minus margins */
        max-width: calc(33.33% - 30px); /* Limit the maximum width */
        margin: 15px; /* Adjust spacing between columns */
        box-sizing: border-box; /* Include padding and borders in width */
    }

    @media screen and (max-width: 768px) {
        .col-lg-3, .col-md-4 {
            flex: 0 0 calc(50% - 30px); /* Show two columns on smaller screens */
            max-width: calc(50% - 30px);
        }
    }

    @media screen and (max-width: 480px) {
        .col-lg-3, .col-md-4 {
            flex: 0 0 100%; /* Show one column on very small screens */
            max-width: 100%;
        }
    }
}

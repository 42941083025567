.membership{
    background: url(../../../../public/images/membershipBack.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 100px 0;
    text-align: center;
    @media screen and (max-width:992px){
        padding: 70px 0;
        background-size: 100% 100%;
       }
        h2{
            font-size: 48px;
            color: #fff;
            margin-bottom: 10px;
            @media screen and (max-width:1200px){
                font-size: 38px;
                line-height: 55px;
              }
        }
        p{
            font-size: 16px;
            color: #ffffffa2;
            margin: 0 0 25px;
            margin: 0 auto 35px;
        }
        .formGroup{
            position: relative;
            max-width: 444px;
            margin: 0 auto;
            input{
                width: 100%;
                height: 66px;
                background-color: #1d201f;
                border: none;
                border-radius: 100px;
                font-size: 16px;
                color: #fff;
                padding: 10px 30px;
                outline: 0;
            }
            button{
                font-size: 16px;
                font-weight: 500;
                background: #28988f;
                color: white;
                width: 136px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                border-radius: 100px;
                transition: all 0.4s;
                position: absolute;
                outline: none;
                right: 10px;
                top: 50%;
                transform: translate(0, -50%);
                img{
                    margin-left: 10px;
                    width: 20px;
                    transition: all 0.4s;
                }
                &:hover{
                    transition: all 0.4s;
                    img{
                        margin-left: 20px;
                        transform: translate(0, 0);
                    }
                }
            }
        }
}
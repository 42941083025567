.navbar{
  padding: 20px 0 !important;
  transition: all 0.5s;
  background-color: white;
}
.navbar-collapse {
    flex-grow: inherit !important;
}
.navbar-nav{
  align-items: center;
}
a{
  text-decoration: none;
}
  .nav-link {
    transition: all 0.3s !important;
    position: relative;
    font-family: "DM Sans", sans-serif;
    font-size: 16px !important;
    font-style: normal;
    line-height: normal;
    font-weight: 400 !important;
    color: #051312 !important;
    padding: 5px 0 !important;
    opacity: 0.5;
  }
  .nav-link.active {
    font-weight: 500 !important;
    opacity: 1;

  }
  
  .nav-link span {
    transition: all 0.3s;
  }
  .nav-link::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    width: 17px;
    height: 2px;
    border-radius: 10px;
    background-color: #1C7A73;
    transform: scale(0.1, 1);
  }
  .nav-link.active::before {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    z-index: 1;
    opacity: 1;
    transform: none;
    transition: all 0.3s;
    width: 17px;
    height: 2px;
    border-radius: 10px;
    background-color: #1C7A73;
  }
  .nav-link:hover span {
    color: #051312;
  }
  .nav-link:hover{
    opacity: 1;
  }
  .nav-link:hover::before {
    opacity: 1;
    transform: scale(1, 1);
  }
  
  .nav-link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: transparent;
  }
  .navbar.scrolling{
    background-color: #fff;
    box-shadow: 0px 1px 13px 5px rgb(110 110 110 / 30%);
    padding: 15px 0;
    transition: all 0.5s;
  }
  .nav-link:hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
  }
  .lap_btn{
    display: none;
  }
  .mbl_btn{
    display: block;
    text-decoration: none;
  }
  .loginBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: #fff;
    border: 1px solid #05131215;
    padding: 7px 15px;
    transition: all 0.5s;
    width: 148px;
    height: 49px;
    span{
      margin: 0 7px;
    }
    .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
      background-color: transparent;
      color: #051312;
    
    }
    .dropdown-toggle{
      background: transparent;
      color: #051312;
      border: none;
      &:hover{
        background-color: transparent;
        color: #051312;
      }
      &:active{
        background-color: transparent;
        color: #051312;
      }
      &::after{
        display: none;
      }
    }
    .dropdown-menu{
      left: -120px !important;
      border: none;
      box-shadow: 0px 5px 25px 0px #0000000D;
      border-radius: 30px;
      width: 300px;
      padding: 10px;
      margin-top: 23px;
      background-color: rgb(255 255 255 / 94%);
      .dropdown-item{
        padding: 10px 20px;
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.5px;
        color: #051312;
        &:hover{
          font-weight: 600;
          background-color: transparent;
          padding: 10px 20px;
        }
      }
      a,button{
        display: block;
    width: 100%;
    background-color: transparent;
    border: 0;
    text-align: start;
        padding: 10px 20px;
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.5px;
        color: #051312;
        margin: 0;
        &:hover{
          font-weight: 600;
          background-color: transparent;
          padding: 10px 20px;
        }
      }
    }
    .img1{
      width: 14px;
    }
    .img2{
      width: 10px;
    }
    &:hover{
      box-shadow: 0px 1px 13px 5px rgba(226, 226, 226, 0.3);
      
    }
  }
  .customToggle{
    border: 0 !important;
    padding: 0 !important;
    outline: 0;
    box-shadow: none;
    .navbar-toggler-icon{
      display: none;
    }
   &::after{
     content: "";
     position: absolute;
     top: 15px;
     right: 5%;
     width: 40px;
     height: 40px;
    background: url(../../assets/images/toggler.svg) no-repeat;
    filter: brightness(0.5);
   }
  }
  .cross{
    border: 0 !important;
    padding: 0 !important;
    outline: 0;
    box-shadow: none;
    &.navbar-toggler{
      border: 0 !important;
      padding: 0 !important;
      outline: 0;
      box-shadow: none;
    }
    .navbar-toggler-icon{
      display: none;
    }
   &::after{
     content: "";
     position: absolute;
     top: 25px;
     right: 6%;
     width: 22px;
     height: 25px;
    background: url(../../assets/images/cross.svg) no-repeat;
    filter: brightness(0.5);
   }
  }
  @media screen and (max-width: 991px) {
    .navbar-collapse{
      position: absolute;
      background: #f4f9f9;
      width: 100%;
      height: 100vh !important;
      top: 0;
      left: 0;
      padding: 20px;
    }
    .loginBtn .dropdown-menu{
      top: -305px !important;
      position: absolute !important;
      background: white !important;
      width: 225px !important;
    }
    .navbar-nav{
      gap: 30px;
      margin-top: 35px;
      .lap_btn{
        display: block;
      }
      .nav-link.active{
        background: #1C7A73;
        border-radius: 10px;
        padding: 15px 20px !important;
        font-family: "DM Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: -0.5px;
        color: #fff !important;
        border: none;
        cursor: pointer;
        transition: background-size 0.4s ease-in-out;
        &::after, &::before{
          display: none;
        }
      }
      .nav-link{
        padding: 5px 20px !important;
        &::after, &::before{
          display: none;
        }
      }
      .nav-link.active:hover {
        background-size: 110%;
      }
    }
  }
  @media screen and (max-width: 991px) {

  .mbl_btn{
    display: none;
  }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        gap: 80px;
    }
}
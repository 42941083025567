.AccountManagement{
    background: url(../../../public/images/blogback.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0;
    @media screen and (max-width:992px){
        padding: 50px 0;
       }
    .card{
        background-color: white;
        padding: 30px;
        border-radius: 30px;
        border: 0;
        @media screen and (max-width:600px){
            padding: 20px;
           }
        .cardInner{
            background: #F4F9F9;
            border: none;
            border-radius: 30px;
            padding: 30px;
            @media screen and (max-width:600px){
                padding: 20px;
               }
            h3{
                font-size: 30px;
                color: #000;
                margin-bottom: 30px;
                font-family: "DM Sans", sans-serif !important;
                @media screen and (max-width:1200px){
                    font-size: 24px;
                    line-height: 55px;
                  }
            }
            h2{
                font-size: 30px;
                color: #000;
                margin-top: 20px;
                font-weight: 600;
                margin-bottom: 20px;
                font-family: "DM Sans", sans-serif !important;
                @media screen and (max-width:1200px){
                    font-size: 24px;
                    line-height: 55px;
                  }
            }
            .cards{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                @media screen and (max-width:1100px){
                    flex-wrap: wrap;
                   }
                .card{
                    background: #fff;
                    border-radius: 10px;
                    padding: 20px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                flex-wrap: wrap;
                flex-direction: row;
                    @media screen and (max-width:992px){
                        padding: 15px;
                       }
                       .block{
                    h4{
                        font-size: 22px;
                        font-weight: 600;
                        color: #000;
                        font-family: "DM Sans", sans-serif !important;
                        @media screen and (max-width:992px){
                            font-size: 16px;
                           }
                           span{
                            color: #1C7A73;
                            font-size: 12px;
                            margin-left: 5px;
                           }
                    }
                    p{
                        font-size: 14px;
                        color: #051312;
                        @media screen and (max-width:992px){
                            font-size: 14px;
                           }
                    }
                       }
                       img{
                        width: 80px;
                        @media screen and (max-width:992px){
                            width: 60px;
                           }
                    }
                }
            }
            table{
                margin: 0;
                thead{
                    tr{
                        th{
                            background: transparent;
                            border: 0;
                            font-size: 18px;
                            color: #051312;
                            padding-bottom: 15px;
                            white-space: nowrap;

                        }
                    }
                }
                tbody{
                    tr{
                        vertical-align: middle;
                        border-bottom: 10px solid #f4f9f9;
                        td{
                            color: #051312;
                            font-size: 14px;
                            white-space: nowrap;

                            a{
                                display: block;
                                color: #1C7A73;
                                font-size: 10px;
                            }
                            &:first-child{
                                border-top-left-radius: 15px;
                                border-bottom-left-radius: 15px;
                            }
                            &:last-child{
                                border-top-right-radius: 15px;
                                border-bottom-right-radius: 15px;
                            }
                        }
                    }
                }
            }
            .form-group{
                margin-bottom: 15px;
                position: relative;
                label{
                    font-size: 16px;
                    margin-bottom: 10px;
                    font-weight: 600;
                }
                input{
                    width: 100%;
                    padding: 15px 20px 15px 20px;
                    font-size: 14px;
                    border: 1px solid #fff;
                    border-radius: 10px;
                    outline: 0;
                    background-color: #fff;
                }
            }
           
            .seeAll{
                font-size: 16px;
                font-weight: 500;
                background: linear-gradient(285deg, #0B5B4C 0, #2B9F97 95%);
                color: white;
                width: 137px;
                height: 53px;
                margin-top: 20px;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                transition: all 0.4s;
                @media screen and (max-width:992px){
                    margin: 30px auto 0;
                   }
                img{
                    margin-left: 10px;
                    width: 20px;
                    transition: all 0.4s;
                }
                &:hover{
                    background: linear-gradient(90deg, #0B5B4C 0, #2B9F97 95%);
                    transition: all 0.4s;
                    img{
                        margin-left: 20px;
                        transform: translate(0, 0);
                    }
                }
            }
        }
    }
}